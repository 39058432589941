<template>
    <div :class="classContainer">
        <img src="img/loading.svg" class="w-32" alt="" />
    </div>
</template>

<script>
export default {
    name: 'Loading',
    props: {
        classContainer: {
            type: String,
            required: false,
            default: 'w-full h-full relative opacity-25 flex justify-center items-center'
        }
    }
}
</script>