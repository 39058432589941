<template>
    <div class="w-full flex justify-between relative mb-4">

        <div class="w-full flex flex-col ">
            <span class="lg-title">
                <slot name="module"></slot>
            </span>

            <div v-if="!noFilters" class="w-full flex justify-start items-center">
                <div class="flex items-center">
                    <i class="mdi mdi-calendar text-casika sm-title mr-1"></i>
                    <div class="xs-title mr-6">
                        <slot name="period"></slot>
                    </div>
                </div>
                <div class="flex items-center">
                    <i class="mdi mdi-map-marker text-casika sm-title mr-1"></i>
                    <div class="xs-title mr-6">
                        <slot name="market"></slot>
                    </div>
                </div>
            </div>
        </div>

    </div>
</template>

<script>
    export default {
        name: 'ModuleHeader',
        props: ['noFilters'],
    }
</script>