var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"w-full h-auto px-3 py-3"},[_c('Header'),[_c('div',{staticClass:"grid grid-cols-12 grid-rows-auto extra:grid-rows-5 grid-flow-row gap-3 w-full h-full flex-1"},[_c('div',{staticClass:"\n                    module\n                    row-span-auto\n                    sm:row-span-1\n                    extra:row-span-full\n                    col-span-12\n                    sm:col-span-12\n                    extra:col-span-3\n                    logisticOrders\n                "},[_c('ModuleTab'),_c('All',{attrs:{"action":"Request","model":"ApiRequest","immediate":true,"sensitive":true,"query":_vm.commonComputedQuery,"data":_vm.orders,"config":{ url: 'logistic-orders' }},on:{"update:data":function($event){_vm.orders=$event}},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var loading = ref.loading;
return [(loading)?_c('Loading'):_c('LogisticOrders',{attrs:{"data":_vm.orders.data}})]}}])})],1),_c('div',{staticClass:"module row-span-auto sm:row-span-1 col-span-12 extra:col-span-9"},[_c('ModuleTab'),_c('All',{attrs:{"action":"Request","model":"ApiRequest","immediate":true,"sensitive":true,"query":_vm.commonComputedQuery,"data":_vm.ordersAvg,"config":{ url: 'logistic-orders-avgs' }},on:{"update:data":function($event){_vm.ordersAvg=$event}},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var loading = ref.loading;
return [(loading)?_c('Loading'):_c('LogisticAverage',{attrs:{"data":_vm.ordersAvg.data}})]}}])})],1),_c('div',{staticClass:"module row-span-auto lg:row-span-auto col-span-12 extra:col-span-9"},[_c('ModuleTab'),_c('All',{attrs:{"action":"Request","model":"ApiRequest","immediate":true,"sensitive":true,"query":_vm.commonComputedQuery,"data":_vm.fails,"config":{ url: 'logistic-errors' }},on:{"update:data":function($event){_vm.fails=$event}},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var loading = ref.loading;
return [(loading)?_c('Loading'):_c('LogisticFails',{attrs:{"data":_vm.fails}})]}}])})],1),_c('div',{staticClass:"module row-span-auto sm:row-span-3 lg:row-span-3 col-span-12 extra:col-span-9"},[_c('ModuleTab'),_c('All',{attrs:{"action":"Request","model":"ApiRequest","immediate":true,"sensitive":true,"query":_vm.commonComputedQuery,"data":_vm.pickers,"config":{ url: 'logistic-pickers-and_lines' }},on:{"update:data":function($event){_vm.pickers=$event}},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var loading = ref.loading;
return [(loading)?_c('Loading'):_c('LogisticPickers',{attrs:{"data":_vm.pickers.data,"period":_vm.period,"market":_vm.market.name}})]}}])})],1)])]],2)}
var staticRenderFns = []

export { render, staticRenderFns }