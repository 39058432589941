<template>
  <div class="bg-casika bg-opacity-75 absolute left-0 top-0 mt-4 h-6 w-1 rounded-tr-md rounded-br-md"></div>
</template>

<script>
export default {

}
</script>

<style>

</style>