<template>
    <swiper class="swiper w-full h-full flex" :ref="refer" :options="swiperOption" :globalOptions="swiperOption">
        <swiper-slide
            v-if="delay"
            v-for="(item, index) in slides"
            :key="index"
            class="w-full"
            :data-swiper-autoplay="delay"
        >
            <slot :name="item.id"></slot>
            <slot name="slide" :item="item"></slot>
        </swiper-slide>
        <swiper-slide v-else v-for="(item, index) in slides" :key="index" class="w-full">
            <slot :name="item.id"></slot>
            <slot name="slide" :item="item"></slot>
        </swiper-slide>
    </swiper>
</template>

<script>
    export default {
        name: 'SwiperComponent',
        props: {
            refer: {
                type: String,
                required: true,
                default: 'swiperComponent',
            },
            slides: {
                type: [Object, Array],
                required: true,
                default: () => [],
            },
            delay: {
                type: Number,
                required: false,
                default: undefined,
            },
            index: {
                type: String,
                required: false,
            },
            swiperOption: {
                type: Object,
                required: false,
                default: () => ({
                    slidesPerView: 1,
                    spaceBetween: 15,
                    // width: 1200,
                    speed: 1000,
                    centeredSlides: true
                }),
            },
        },
        computed: {
            theSwiper() {
                return this.$refs[this.refer];
                //return this.$refs.swiperComponent
            },
        },
        mounted() {
            this.theSwiper.swiper.autoplay.start();
            //console.log('object :>> ', this.theSwiper.$swiper.activeIndex);
        },
    };
</script>
