<template>
    <div :class="classContainer">
        <div class="box-container">
            <div class="box-title">
                <slot name="header"></slot>
            </div>
            <slot name="content"></slot>
        </div>
    </div>
</template>

<script>
export default {
    name: 'BoxData',
    props: {
        classContainer: {
            type: String,
            required: false,
            default: 'box w-full'
        }
    }
}
</script>