<template>
    <div class="detail w-full py-3 lg:py-1 px-2 flex flex-col md:flex-row">
        <slot name="photo"></slot>        
        <slot name="content"></slot>
    </div>
</template>

<script>
    export default {
        name: 'ListLarge',
    }
</script>