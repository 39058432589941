<template>
    <div class="w-full flex flex-col">
        <ModuleHeader :noFilters="true">
            <template v-slot:module>
                Media pedidos/ hora
            </template>
        </ModuleHeader>

        <template>
            <div class="w-full grid grid-cols-1 md:grid-cols-3 gap-2">
                <Box>
                    <template v-slot:header>
                        Día anterior
                    </template>
                    <template v-slot:content>
                        <div class="w-full flex justify-center">
                            <div class="text-white text-3xl xl:text-4xl font-medium py-1">{{data.last_day | numFormat('0')}}</div>
                        </div>
                    </template>
                </Box>
                <Box>
                    <template v-slot:header>
                        Mes corriente
                    </template>
                    <template v-slot:content>
                        <div class="w-full flex justify-center">
                            <div class="text-white text-3xl xl:text-4xl font-medium py-1">{{data.current_month | numFormat('0')}}</div>
                        </div>
                    </template>
                </Box>
                <Box>
                    <template v-slot:header>
                        <div class="w-full flex justify-between items-center">
                            <span>Récord Histórico</span>
                            <span class="text-casika text-sm font-medium py-1"><i class="mdi mdi-calendar text-gray sm-title mr-1"></i>{{ data.record.date | moment('DD MMM YY') }}</span>
                        </div>                        
                    </template>
                    <template v-slot:content>
                        <div class="w-full flex justify-center">
                            <div class="text-white text-3xl xl:text-4xl font-medium py-1">{{ data.record.value  | numFormat('0,0')}}</div>
                        </div>
                    </template>
                </Box>
            </div>
        </template>
    </div>
</template>

<script>
    import ModuleHeader from '@/components/header/ModuleHeader.vue';
    import Box from '@/components/Box.vue';
    export default {
        name: 'LogisticAverage',
        components: {
            ModuleHeader,
            Box,
        },
        props: {
            data: {
                type: [Array, Object],
                required: true,
                default: () => ({})
            },
        },
    }
</script>