<template>
    <div class="relative chart-wrap">
        <highcharts  class="chart w-full h-full" :options="options" :ref="chartId" :id="chartId"></highcharts>
    </div>
</template>

<script>
export default {
    name: 'StackedChart',
    props: ['chartId','data'],
    computed: {
        // series() {
        //     return this.data.map((item)=>{
        //         return item;
        //     })
        // },
        options() {
            return {
                chart: {
                    type: 'column',
                    plotBackgroundColor: null,
                    plotBorderWidth: null,
                    plotShadow: false,
                    backgroundColor:'rgba(255,255,255,0)',
                    spacingBottom: 10,
                    spacingTop: 100,
                    spacingRight: 0,
                    spacingLeft: 0,
                    marginTop: 10,
                    marginRight: 0,
                    marginRLeft: 0,
                },
                title: {
                    text: ''
                },
                subtitle: {
                    text: ''
                },
                credits:{
                    enabled: false
                },
                xAxis: [{
                    type: 'datetime',
                    dateTimeLabelFormats: {
                        month: '%b'
                    },
                    lineColor: 'rgba(255,255,255,0)',
                    title: {
                        text: null
                    },
                    labels: {
                        style:{
                            color:'#79849A',
                            fontWeight: 'medium',
                            fontSize: '7.5px',
                        }
                    },
                    tickPixelInterval: 75,
                    tickWidth: 0,
                },{
                    type: 'datetime',
                    dateTimeLabelFormats: {
                        month: '%b'
                    },
                    lineColor: 'rgba(255,255,255,0)',
                    title: {
                        text: null
                    },
                    labels: {
                        enabled: false,
                    },
                    tickPixelInterval: 75,
                    tickWidth: 0,
                    opposite: true
                }],
                yAxis: {
                    title: {
                        text: '',
                    },
                    lineColor: '#79849A',
                    lineWidth: 1,
                    gridLineColor: 'transparent',
                    labels: {
                        enabled: true,
                        style:{
                            color: '#79849A',
                            fontWeight: 'medium',
                            fontSize: '7.5px',
                        },
                    },
                    // min: 0,
                    // max: 100,
                },
                tooltip: {
                    enabled:true,
                    style: {
                        color: "#fff",
                    },
                    shared: true,
                    borderRadius: 20,
                    borderWidth: 0,
                    headerFormat: '<span style="font-size:.65rem; color: rgb(121, 132, 154);">{point.key}</span><br>',
                    xDateFormat: "%B %Y",
                    pointFormat:
                    '<span class="capitalize" style="color:{point.color}">{series.name}</span>: <b>{point.y:,.0f}</b><br/>',
                },
                plotOptions: {
                    column: {
                        stacking: 'normal',
                        grouping: true,
                        pointPadding: 0.3,
                        pointPlacement: 0.2,
                        borderRadius: 3,
                        borderWidth: 1,
                        borderColor: '#202837',
                        dataLabels: {
                            enabled: false
                        }
                    },
                    series: {
                        shadow: true,
                    }
                },
                legend: {
                    enabled: false
                },
                credits: {
                    enabled: false
                },
                data: {
                    enablePolling: true,
                    dataRefreshRate: 1
                },
                series: [{
                    name: 'Roturas por transporte',
                    data: this.data.transport_breaks,
                    color: {
                        linearGradient: {
                            x1: 0,
                            x2: 0,
                            y1: 0,
                            y2: 1
                        },
                        stops: [
                            [0, '#EF6C45'],
                            [1, '#EF6C45']
                        ]
                    },
                    stack: 'One',
                    zIndex: 2,
                    pointWidth: 20,
                },{
                    name: 'Artículo faltante',
                    data: this.data.missing_article,
                    color: {
                        linearGradient: {
                            x1: 0,
                            x2: 0,
                            y1: 0,
                            y2: 1
                        },
                        stops: [
                            [0, '#FFA8A8'],
                            [1, '#FFA8A8']
                        ]
                    },
                    stack: 'One',
                    zIndex: 2,
                    pointWidth: 20,
                },{
                    name: 'Artículo equivocado',
                    data: this.data.wrong_article,
                    color: {
                        linearGradient: {
                            x1: 0,
                            x2: 0,
                            y1: 0,
                            y2: 1
                        },
                        stops: [
                            [0, '#FF5D61'],
                            [1, '#FF5D61']
                        ]
                    },
                    stack: 'One',
                    zIndex: 2,
                    pointWidth: 20,
                },{
                     name: 'Roturas por transporte anterior',
                    data: this.data.transport_breaks_previous,
                    color: {
                        linearGradient: {
                            x1: 0,
                            x2: 0,
                            y1: 0,
                            y2: 1
                        },
                        stops: [
                            [0, '#EF6C4555'],
                            [1, '#EF6C4555']
                        ]
                    },
                    stack: 'Two',
                    zIndex: 1,
                    pointWidth: 8,
                    xAxis: 1,
                },{
                    name: 'Artículo faltante anterior',
                    data: this.data.missing_article_previous,
                    color: {
                        linearGradient: {
                            x1: 0,
                            x2: 0,
                            y1: 0,
                            y2: 1
                        },
                        stops: [
                            [0, '#FFA8A855'],
                            [1, '#FFA8A855']
                        ]
                    },
                    stack: 'Two',
                    zIndex: 1,
                    pointWidth: 8,
                    xAxis: 1,
                },{
                    name: 'Artículo equivocado anterior',
                    data: this.data.wrong_article_previous,
                   
                    color: {
                        linearGradient: {
                            x1: 0,
                            x2: 0,
                            y1: 0,
                            y2: 1
                        },
                        stops: [
                            [0, '#FF5D6155'],
                            [1, '#FF5D6155']
                        ]
                    },
                    stack: 'Two',
                    zIndex: 1,
                    pointWidth: 8,
                    xAxis: 1,
                }]
            }
        }
    },
    data() {
        return {
        }
    },

    methods: {

    },
    watch: {

    },
    mounted() {
    }
};
</script>
