<template>
    <div class="w-full h-auto px-3 py-3">
        <Header />

        <template>
            <div class="grid grid-cols-12 grid-rows-auto extra:grid-rows-5 grid-flow-row gap-3 w-full h-full flex-1">
                <div
                    class="
                        module
                        row-span-auto
                        sm:row-span-1
                        extra:row-span-full
                        col-span-12
                        sm:col-span-12
                        extra:col-span-3
                        logisticOrders
                    "
                >
                    <ModuleTab />
                    <All
                        action="Request"
                        model="ApiRequest"
                        :immediate="true"
                        :sensitive="true"
                        :query="commonComputedQuery"
                        :data.sync="orders"
                        v-slot="{ loading }"
                        :config="{ url: 'logistic-orders' }"
                    >
                        <Loading v-if="loading"></Loading>
                        <LogisticOrders v-else :data="orders.data" />
                    </All>
                </div>

                <div class="module row-span-auto sm:row-span-1 col-span-12 extra:col-span-9">
                    <ModuleTab />
                    <All
                        action="Request"
                        model="ApiRequest"
                        :immediate="true"
                        :sensitive="true"
                        :query="commonComputedQuery"
                        :data.sync="ordersAvg"
                        v-slot="{ loading }"
                        :config="{ url: 'logistic-orders-avgs' }"
                    >
                        <Loading v-if="loading"></Loading>
                        <LogisticAverage v-else :data="ordersAvg.data" />
                    </All>
                </div>
                <div class="module row-span-auto lg:row-span-auto col-span-12 extra:col-span-9">
                    <ModuleTab />
                    <All
                        action="Request"
                        model="ApiRequest"
                        :immediate="true"
                        :sensitive="true"
                        :query="commonComputedQuery"
                        :data.sync="fails"
                        v-slot="{ loading }"
                        :config="{ url: 'logistic-errors' }"
                    >
                        <Loading v-if="loading"></Loading>
                        <LogisticFails v-else :data="fails" />
                    </All>
                </div>
                <div class="module row-span-auto sm:row-span-3 lg:row-span-3 col-span-12 extra:col-span-9">
                    <ModuleTab />
                    <All
                        action="Request"
                        model="ApiRequest"
                        :immediate="true"
                        :sensitive="true"
                        :query="commonComputedQuery"
                        :data.sync="pickers"
                        v-slot="{ loading }"
                        :config="{ url: 'logistic-pickers-and_lines' }"
                    >
                        <Loading v-if="loading"></Loading>
                        <LogisticPickers v-else :data="pickers.data" :period="period" :market="market.name" />
                    </All>
                </div>
            </div>
        </template>
    </div>
</template>

<script>
    import { state } from '@/store';
    import { All } from '@/api/components';
    import Header from '@/components/header/Header.vue';
    import Loading from '@/components/elements/Loading.vue';
    import ModuleTab from '@/components/elements/ModuleTab';
    import LogisticOrders from '@/components/module/LogisticOrders.vue';
    import LogisticAverage from '@/components/module/LogisticAverage.vue';
    import LogisticFails from '@/components/module/LogisticFails.vue';
    import LogisticPickers from '@/components/module/LogisticPickers.vue';
    import Paginate from '@/api/components/Paginate';

    export default {
        name: 'LogisticPage',
        data() {
            return {
                orders: {},
                ordersAvg: {},
                fails: {},
                pickers: {},
                paginate: {
                    limit: 8,
                    page: 1,
                    skip: 0,
                    order_field: null,
                    order_direction: null,
                },
            };
        },
        components: {
            Paginate,
            All,
            Header,
            Loading,
            ModuleTab,
            LogisticOrders,
            LogisticAverage,
            LogisticFails,
            LogisticPickers,
        },
        computed: {
            commonComputedQuery() {
                return {
                    from: this.period.from,
                    to: this.period.to,
                    country: this.marketId,
                };
            },
            user() {
                return state.user;
            },
            period() {
                return {
                    from: this.$moment(state.period.from).format('YYYY-MM-DD'),
                    to: this.$moment(state.period.to).format('YYYY-MM-DD'),
                };
            },
            market() {
                return state.market;
            },
            marketId() {
                return state.market.id;
            },
        },
    };
</script>

<style>
    @media (min-width: 768px) {
        .logisticOrders {
            min-height: 90vh;
        }
    }
</style>
