<template>
    <div class="w-full flex flex-col flex-1 h-full">
        <ModuleHeader :noFilters="true">
            <template v-slot:module>
                <!-- <swiper :options="swiperVertical" ref="titleSwiper" class="w-full inline h-20 overflow-hidden">
                    <swiper-slide class="w-full flex justify-start">
                        Pickers {{swipeTitle}}
                    </swiper-slide>
                    <swiper-slide class="w-full flex justify-start">
                        Lines {{swipeTitle}}
                    </swiper-slide>
                </swiper> -->
                {{ swiperTitle[slide] }}
            </template>
            <!-- <template v-slot:period>
                {{ period.from | moment('DD MMM YY') }} <i class="mdi mdi-arrow-right"></i>
                {{ period.to | moment('DD MMM YY') }}
            </template>
            <template v-slot:market>
                {{ market }}
            </template> -->
        </ModuleHeader>

        <template>
            <div class="overflow-hidden w-full h-full flex">
                <swiper
                    class="swiper w-full h-full flex"
                    ref="pickLineSwiper"
                    :options="swiperOptionPickers"
                    @slideChangeTransitionStart="changeTitle('picker')"
                >
                    <swiper-slide v-for="item in ['pickers', 'lines']" :key="item" class="w-full">

                        <div class="flex justify-between items-center">
                            <div class="w-1/12"></div>
                            <div
                                class="hidden tb:grid w-full sm:w-11/12 grid-cols-1 gap-2 text-center"
                                :class="item == 'pickers' ? 'tb:grid-cols-3' : 'tb:grid-cols-4'"
                            >
                                <span class="table-header" v-if="item == 'pickers'">Tiempo medio de recogida de carros</span>
                                <span class="table-header" v-if="item == 'lines'">Pedidos</span>
                                <span class="table-header">Errores por artículo equivocado</span>
                                <span class="table-header">Errores por artículo faltante</span>
                                <span class="table-header" v-if="item == 'lines'">Rotura por transporte</span>
                            </div>
                        </div>

                        <div class="grid grid-flow-row" :class="'grid-rows-' + data[item].length" style="height: 97%">
                            <ListLarge v-for="person in data[item]" :key="person.id" class="row-span-1">
                               
                                <template v-slot:photo>
                                    <div class="w-full sm:w-1/12 relative f-center m-3 lg:m-0">
                                        <div v-if="item == 'pickers'" class="w-6 h-6 rounded-full absolute top-0 left-0 bg-body f-center ml-4">
                                            <span class="sm-value font-bold text-casika"> {{ person.id }}</span>
                                        </div>
                                        <div
                                            v-if="item == 'pickers'"
                                            :content="person.name"
                                            v-tippy="{ followCursor: true, arrow: false }"
                                            class="w-16 h-16 rounded-full overflow-hidden shadow-xl"
                                        >
                                            <img
                                                v-if="person.image"
                                                class="w-full h-full object-cover"
                                                :src="backUrl + person.image"
                                                :alt="data.name"
                                            />
                                            <img
                                                v-else
                                                class="w-full h-full object-cover"
                                                src="img/default.jpg"
                                                :alt="data.name"
                                            />
                                        </div>
                                        <div
                                            v-else
                                            :content="person.name"
                                            v-tippy="{ followCursor: true, arrow: false }"
                                            class="w-16 h-16 rounded-full overflow-hidden shadow-xl bg-body f-center text-casika"
                                        >
                                            <div class="text-center text-2xl">
                                               {{ person.id }}
                                            </div>
                                        </div>
                                    </div>
                                </template>

                                <template v-slot:content>
                                    <div
                                        class="w-full sm:w-11/12 grid grid-cols-1 gap-2"
                                        :class="item === 'pickers' ? 'tb:grid-cols-3' : 'md:grid-cols-2 tb:grid-cols-4'"
                                    >
                                        <Box
                                            class="mb-0"
                                            v-for="(element, index) in columnsPerType[item]"
                                            :key="'element-' + index"
                                        >

                                            <template v-slot:content>
                                                
                                                <span class="flex tb:hidden table-header w-full text-center">{{
                                                    columns[element]
                                                }}</span>

                                                <div class="flex justify-between items-center">
                                                    <i class="mdi text-gray2 text-xl" :class="icons[element]"></i>
                                                    <div class="flex justify-around flex-1">
                                                        <div class="flex flex-col items-center">
                                                            <div class="lg-value">
                                                                {{ person[element].yesterday | numFormat('0.0a') }}<span v-if="element == 'average_time'"
                                                                >'</span>
                                                                <span v-else-if="element == 'orders'" class="text-sm"
                                                                >/h</span>
                                                                <span v-else class="text-sm"
                                                                >%</span>
                                                            </div>
                                                            <div class="text-gray1 text-sm">ayer</div>
                                                        </div>
                                                        <div class="flex flex-col items-center">
                                                            <div class="lg-value">
                                                                {{ person[element].last_month | numFormat('0.0a') }}<span v-if="element == 'average_time'"
                                                                >'</span>
                                                                <span v-else-if="element == 'orders'" class="text-sm"
                                                                >/h</span>
                                                                <span v-else class="text-sm"
                                                                >%</span>
                                                            </div>
                                                            <div class="text-gray1 text-sm">mes</div>
                                                        </div>
                                                        <div class="flex flex-col items-center">
                                                            <div class="lg-value">
                                                                {{ person[element].last_trimester | numFormat('0.0a') }}<span v-if="element == 'average_time'"
                                                                >'</span>
                                                                <span v-else-if="element == 'orders'" class="text-sm"
                                                                >/h</span>
                                                                <span v-else class="text-sm"
                                                                >%</span>
                                                            </div>
                                                            <div class="text-gray1 text-sm">trimestre</div>
                                                        </div>
                                                    </div>
                                                </div>
                                            </template>
                                        </Box>
                                    </div>
                                </template>
                            </ListLarge>
                        </div>
                    </swiper-slide>
                </swiper>
            </div>
        </template>
    </div>
</template>

<script>
    import ModuleHeader from '@/components/header/ModuleHeader.vue';
    import ListLarge from '@/components/ListLarge.vue';
    import Box from '@/components/Box.vue';
    export default {
        name: 'LogisticPickers',
        components: {
            ModuleHeader,
            ListLarge,
            Box,
        },
        props: {
            data: {
                type: [Array, Object],
                required: false,
                default: () => ({}),
            },
            period: {
                type: Object,
                required: false,
                default: {},
            },
            market: {
                type: String,
                required: false,
                default: 'Mercado',
            },
        },
        data() {
            return {
                backUrl: '',
                imgError: false,
                slide: 'Pickers',
                // swiperVertical: {
                //     direction: 'vertical',
                //     slidesPerView: 1,
                //     centeredSlides: true,
                //     // autoHeight: true,
                // },
                swiperOptionPickers: {
                    slidesPerView: 1,
                    spaceBetween: 20,
                    centeredSlides: true,
                    speed: 1000,
                    pagination: {
                        el: '.swiper-pagination',
                        dynamicBullets: true,
                    },
                    autoplay: {
                        delay: 15000,
                        disableOnInteraction: false,
                        pauseOnMouseEnter: true
                    },
                },
                swiperTitle: ['Pickers', 'Lines'],
                columns: {
                    orders: 'Pedidos',
                    average_time: 'Tiempo medio de recogida de carros',
                    fails_missing_article: 'Errores por artículo equivocado',
                    fails_wrong_article: '$rrores por artículo faltante',
                    transport: 'Rotura por transporte'
                },
                icons: {
                    average_time: 'mdi-clock',
                    orders: 'mdi-clock',
                    fails_missing_article: 'mdi-help-circle',
                    fails_wrong_article: 'mdi-alert-circle',
                    transport: 'mdi-image-broken-variant'
                },
                columnsPerType: {
                    pickers: [
                        'average_time',
                        'fails_wrong_article',
                        'fails_missing_article'
                    ],
                    lines: [
                        'orders',
                        'fails_wrong_article',
                        'fails_missing_article',
                        'transport'
                    ],
                }
            };
        },
        computed: {
            swiper() {
                return this.$refs.pickLineSwiper.swiper;
            },
            swiperVertical() {
                return this.$refs.titleSwiper.swiper;
            },
        },
        methods: {
            changeTitle() {
                this.slide = this.swiper.activeIndex;
                //this.$refs.titleSwiper.swiper.slideTo(this.swiper.activeIndex, 500);
                //console.log('active? ',this.swiper.activeIndex );
            },
            imgLoadError(image) {
                this.imgError = true;
                this.image = image;
            },
        },
        created() {
            this.swiperTitle[this.slide] = 'Pickers';
            this.backUrl = process.env.VUE_APP_API_URL;
        },
    };
</script>

<style>
    .table-header {
        @apply text-gray2 text-xs text-center;
    }
</style>
