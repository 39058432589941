<template>
    <div class="w-full flex flex-col">
        <ModuleHeader :noFilters="true">
            <template v-slot:module>
                Errores
            </template>
        </ModuleHeader>

        <template>
            <div class="w-full grid grid-cols-1 extra:grid-cols-3 gap-2">
                <Box
                    v-for="(item,index) in fails"
                    :key="item.id"
                >
                    <template v-slot:header>
                        <div class="flex items-center">
                            <i
                            class="mdi text-gray2 text-xl mr-2"
                            :style="{color: colors[index]}"
                            :class="item.icon"></i>
                            {{item.type}}
                        </div>
                    </template>
                    <template v-slot:content>
                        <div
                            class="flex justify-around my-1">
                            <div class="flex flex-col items-center leading-none pt-3">
                                <div class="text-white text-xl xl:text-2xl font-medium">
                                    {{item.percents.previous_week | numFormat("0,0")}} 
                                    <!-- <small>%</small> -->
                                </div>
                                <i class="mdi mdi-undo text-gray2 text-xl"></i>
                                <div
                                    :content="'Última semana'"
                                    v-tippy="{ followCursor: true, arrow: false }"
                                    class="text-gray1 text-sm cursor-default">semana</div>
                            </div>
                            <div class="flex flex-col items-center leading-none pt-3">
                                <div class="text-white text-xl xl:text-2xl font-medium">
                                    {{item.percents.previous_month | numFormat("0,0")}} 
                                    <!-- <small>%</small> -->
                                </div>
                                <i class="mdi mdi-undo text-gray2 text-xl"></i>
                                <div
                                    :content="'Último mes'"
                                    v-tippy="{ followCursor: true, arrow: false }"
                                    class="text-gray1 text-sm cursor-default">mes</div>
                            </div>
                            <div class="flex flex-col items-center leading-none pt-3">
                                <div class="text-white text-xl xl:text-2xl font-medium">
                                    {{item.percents.previous_year | numFormat("0,0")}} 
                                    <!-- <small>%</small> -->
                                </div>
                                <i class="mdi mdi-undo text-gray2 text-xl"></i>
                                <div
                                    :content="'Último año'"
                                    v-tippy="{ followCursor: true, arrow: false }"
                                    class="text-gray1 text-sm cursor-default">año</div>
                            </div>
                        </div>
                    </template>
                </Box>
            </div>            
        </template>
    </div>
</template>

<script>
    import ModuleHeader from '@/components/header/ModuleHeader.vue';
    import Box from '@/components/Box.vue';
    export default {
        name: 'LogisticAFails',
        components: {
            ModuleHeader,
            Box,
        },
        data() {
            return {
                colors: ['#FF5D61','#FFA8A8','#D35935'],
            }
        },
        props: {
            data: {
                type: [Array, Object],
                required: true,
                default: () => ({})
            },
        },
        computed: {
            fails(){
                return  [
                    {
                        id: 0,
                        type: 'Artículo equivocado',
                        icon: 'mdi-clock',
                        percents: {
                            previous_week: this.data.data.wrong_article ? this.data.data.wrong_article.week : 0,
                            previous_month: this.data.data.wrong_article ? this.data.data.wrong_article.month : 0,
                            previous_year: this.data.data.wrong_article ? this.data.data.wrong_article.year : 0
                        }
                    },{
                        id: 1,
                        type: 'Artículo faltante',
                        icon: 'mdi-help-circle',
                        percents: {
                            previous_week: this.data.data.missing_article ? this.data.data.missing_article.week : 0,
                            previous_month: this.data.data.missing_article ? this.data.data.missing_article.month : 0,
                            previous_year: this.data.data.missing_article ? this.data.data.missing_article.year : 0
                        }
                    },{
                        id: 2,
                        type: 'Roturas por transporte',
                        icon: 'mdi-alert-circle',
                        percents: {
                            previous_week: this.data.data.transport_breaks ? this.data.data.transport_breaks.week : 0,
                            previous_month: this.data.data.transport_breaks ? this.data.data.transport_breaks.month : 0,
                            previous_year: this.data.data.transport_breaks ? this.data.data.transport_breaks.year : 0
                        }
                    },
                ]
            }
        }
    }
</script>