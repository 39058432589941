<template>
    <div class="w-full flex flex-col">
        <ModuleHeader :noFilters="true">
            <template v-slot:module> Pedidos </template>
            <!-- <template v-slot:period>
                {{ period.from | moment('DD MMM YY') }} <i class="mdi mdi-arrow-right"></i>
                {{ period.to | moment('DD MMM YY') }}
            </template>
            <template v-slot:market>
                {{ market.name }}
            </template> -->
        </ModuleHeader>

        <template>
            <Box v-if="data">
                <template v-slot:content>
                    <div class="flex flex-col items-center">
                        <span class="mt-6 mb-3 text-center">
                            <div class="md-title">Total pedidos</div>
                            <div class="text-4xl xl:text-5xl text-white font-bold leading-none">
                                {{ data.total | numFormat('0,0') }}
                            </div>
                        </span>

                        <div class="separator my-2"></div>

                        <span class="my-6 text-center">
                            <div class="md-title">Pedidos enviados ayer</div>
                            <div class="text-4xl xl:text-5xl font-bold text-gray2 leading-none">
                                {{ data.yesterday | numFormat('0,0') }}
                            </div>
                        </span>
                    </div>
                </template>
            </Box>

            <template>
                <div class="md-title">Errores últimos 6 meses</div>

                <div class="w-full h-56">
                    <Stacked :chartId="'chartId'" :data="data.fails_evolution" />
                </div>

                <Box class="h-40">
                    <template v-slot:header> Ranking mensual </template>
                    <template v-slot:content>
                        <Swiper
                            :slides="[{ id: 'pickers' }, { id: 'lines' }]"
                            :refer="'monthly-ranking'"
                            :delay="delay"
                        >
                            <template v-slot:pickers>
                                <div class="flex justify-around items-center mt-5">
                                    <i class="mdi mdi-podium-gold text-casika text-2xl"></i>

                                    <div
                                        v-for="(data, k) in orderedPickers"
                                        :key="data.id"
                                        class="
                                            w-8
                                            md:w-10
                                            h-8
                                            md:h-10
                                            bg-body
                                            f-center
                                            rounded-full
                                            overflow-hidden
                                            shadow-xl
                                            text-xl
                                        "
                                        :class="{ 'w-16 md:w-20 h-16 md:h-20 text-3xl': k == 0 }"
                                    >
                                        <img
                                            v-if="!imgError"
                                            class="w-full h-full object-cover"
                                            :src="backUrl + data.image "
                                            @error="imgLoadError(data.id)"
                                            :alt="data.name"
                                        />
                                        <img
                                            v-else
                                            class="w-full rounded-lg h-full object-cover"
                                            src="img/default.jpg"
                                            :alt="data.name"
                                        />
                                    </div>
                                </div>
                            </template>
                            <template v-slot:lines>
                                <div class="flex justify-around items-center mt-5">
                                    <i class="mdi mdi-podium-gold text-casika text-2xl"></i>

                                    <div
                                        v-for="(data, k) in orderedLines"
                                        :key="data.id"
                                        class="
                                            w-8
                                            md:w-10
                                            h-8
                                            md:h-10
                                            bg-body
                                            f-center
                                            rounded-full
                                            overflow-hidden
                                            shadow-xl
                                            text-xl
                                        "
                                        :class="{ 'w-16 md:w-20 h-16 md:h-20 text-3xl': k == 0 }"
                                    >
                                        <div class="text-casika font-bold">{{ data.position }}</div>
                                    </div>
                                </div>
                            </template>
                        </Swiper>
                    </template>
                </Box>
            </template>
        </template>
    </div>
</template>

<script>
    import ModuleHeader from '@/components/header/ModuleHeader.vue';
    import Stacked from '@/components/charts/Stacked.vue';
    import Box from '@/components/Box.vue';
    import Swiper from '@/components/Swiper.vue';
    import { state } from '@/store';
    export default {
        name: 'LogisticOrders',
        components: {
            ModuleHeader,
            Stacked,
            Box,
            Swiper,
        },
        props: {
            data: {
                type: [Array, Object],
                required: false,
                default: () => ({}),
            },
        },
        data() {
            return {
                backUrl: '',
                imgError: false,
                delay: 10000,
            };
        },
        computed: {
            orderedPickers() {
                return this.data.ranking.pickers.map(item => item).sort((a, b) => a.position - b.position);
            },
            orderedLines() {
                return this.data.ranking.lines.map(item => item).sort((a, b) => a.position - b.position);
            },
            user() {
                return state.user;
            },
            period() {
                return {
                    from: this.$moment(state.period.from).format('YYYY-MM-DD'),
                    to: this.$moment(state.period.to).format('YYYY-MM-DD'),
                };
            },
            market() {
                return state.market;
            },
            marketId() {
                return state.market.id;
            },
            slide_ranking() {
                let ranking = [];
                for (let key in this.data.ranking) {
                    ranking.push(key);
                }
                return ranking;
            },
        },
        methods: {
            imgLoadError(image) {
                this.imgError = true;
                this.image = image;
            },
        },
        created() {
            this.backUrl = process.env.VUE_APP_API_URL;
        }
    };
</script>
